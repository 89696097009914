<template>
  <div class="helpCenterBox">
    <FullLoading v-if="loading" />
    <div class="helpCenterBox_title"><span v-if="someParam!==undefined">{{ title }}</span></div>
    <div class="helpCenterBox_content">
      <span v-if="someParam!==undefined" v-html="someParam"></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {getHelpInfo} from "../../api/apiconfig/main";

const route = useRoute();
const someParam = ref();
const title = ref();
const loading = ref(true);
//获取详情列表(接口)
const helpInfo = (async () => {
  const response = await getHelpInfo({id: route.params.id})
  if (response.data) {
    someParam.value = response.data.content
    title.value = response.data.title
  }
  loading.value = false
})
await helpInfo()

useHead({
  title:'松鼠快图_帮助中心_'+title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心，'+title.value},
    {hid:'description',name:'description','content':'松鼠快图帮助中心，提供使用过程中的帮助内容，'+title.value+'。'},
  ]
})

</script>

<style lang="less" scoped>
.helpCenterBox {
  .helpCenterBox_title {
    padding: 0 20px;
    line-height: 60px;
    margin: 0 15px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid rgba(216, 185, 185, .1);
  }

  .helpCenterBox_content {
    padding: 50px;
  }
}
</style>