<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import helpCenterSubpagePc  from "@/src/components/helpCenter/helpCenterSubpagePc.vue";
import helpCenterSubpageWap from "@/src/components/wap/helpCenter/info.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(helpCenterSubpagePc, helpCenterSubpageWap)
</script>
<style scoped>
body{

}
</style>
