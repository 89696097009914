<template>
  <div>
    <PanelHeader class="head"></PanelHeader>
    <div class="helpSubpageBox">
      <div class="helpSubpageBox_left">
        <FullLoading v-if="fullscreenLoading" />
        <el-tree
            accordion
            :default-checked-keys="defaultKeys"
            :current-node-key="currentNodeKey"
            highlight-current
            node-key="id"
            empty-text="暂无数据"
            :default-expanded-keys="defaultKeys"
            class="helpSubpageTree"
            :data="listData"
            :props="defaultProps"
            @node-click="handleNodeClick">
          <template #default="{ node, data }">
            <div class="custom-tree-node">
              {{ node.label }}
            </div>
          </template>
        </el-tree>
      </div>
      <div class="helpSubpageBox_right">
        <help-center-details></help-center-details>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PanelHeader from "../../../layout/components/PanelHeader.vue";
import {getHelpList} from "../../api/apiconfig/main";
import HelpCenterDetails from "./helpCenterDetails.vue";
const defaultProps = {
  children: 'children',
  label: 'title',
}
const currentNodeKey = ref()
const defaultKeys = ref([])
const router = useRouter()
const route = useRoute()
const listData = ref([])
const fullscreenLoading = ref(true)
currentNodeKey.value = Number(route.params.id)
const routerData = (() => {
  if (route.params.id) {
    defaultKeys.value = [Number(route.params.id)]
  }
})
// 获取列表(接口)
const helpList = (async () => {
  const response = await getHelpList()
  if (response.data) {
    listData.value = response.data
  } else {
    listData.value = []
  }
  fullscreenLoading.value = false
  routerData()
})
await helpList()
//菜单点击触发方法
const handleNodeClick = (data: any) => {
  if(!data.children){
    router.replace( { params: { id:data.id } })
  }
}
</script>
<style>
.helpSubpageTree .el-tree-node__content {
  height: 40px;
}
</style>
<style lang="scss" scoped>
.head {
  position: fixed;
}

.helpSubpageBox {
  width: 100%;
  display: flex;
  padding: 80px 50px;
  user-select: none;

  .helpSubpageBox_left {
    width: 288px;
    height: calc(100vh - 80px);
    overflow: auto;
  }

  .helpSubpageBox_right {
    flex: 1;
    height: calc(100vh - 80px);
    overflow: auto;
  }
}
</style>

