<template>
  <div class="title">{{title}}</div>
  <div class="content" v-html="data"></div>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import {getHelpInfo} from "@/src/api/apiconfig/main";
const route = useRoute();
const title = ref('');
const data = ref('');
//获取详情列表(接口)
const helpInfo = (async () => {
  const response = await getHelpInfo({id: route.params.id})
  if (response.data) {
    data.value = response.data.content
    title.value = response.data.title
  }
})
await helpInfo();

useHead({
  title:'松鼠快图_帮助中心_'+title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':'松鼠快图,帮助中心，'+title.value},
    {hid:'description',name:'description','content':'松鼠快图帮助中心，提供使用过程中的帮助内容，'+title.value+'。'},
  ]
})
</script>
<style>
.title{
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  padding: 0 20px;
}
.content{
  padding:0 20px;
}
</style>